@import 'scss/colors';
@import 'scss/fonts';
@import 'scss/BootstrapOverright';

:root {
  --primaryblue: $primaryblue;
  --digitalblue: #{$blue400};
  --blue100: #{$blue100};
  --blue200: #{$blue200};
  --blue250: #{$blue250};
  --blue400: #{$blue400};
  --blue600: #{$blue600};
  --blue700: #{$blue700};
  --darkblue900: #{$darkblue900};
  --darkblueformenu: #{$darkblueformenu};

  --primarywhite: #{$primarywhite};
  --grey50: #{$grey50};
  --grey100: #{$grey100};
  --grey200: #{$grey200};
  --grey300: #{$grey300};
  --grey400: #{$grey400};
  --grey500: #{$grey500};
  --grey600: #{$grey600};
  --grey700: #{$grey700};
  --grey800: #{$grey800};
  --grey900: #{$grey900};

  --primaryred: #{$primaryred};
  --red50: #{$red50};
  --red200: #{$red200};

  --primarygreen: #{$primarygreen};
  --green50: #{$green50};
  --green200: #{$green200};

  --primaryorange: #{$primaryorange};
  --orange50: #{$orange50};
  --orange120: #{$orange120};

  --primaryblack: #{$primaryblack};
}

html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: $primarywhite;
  font-family: $font-stack;
}

.text-blue {
  color: var(--digitalblue);
}

.text-red {
  color: var(--primaryred);
}
.text-green {
  color: var(--primarygreen);
}

.text-grey600 {
  color: $grey600;
}

.disable-container {
  pointer-events: none !important;
}

.error-message {
  font-size: 12px;
  color: var(--primaryred);
}

.bg-blue {
  background-color: var(--digitalblue) !important;
}

.bg-grey300 {
  background-color: var(--grey300) !important;
}

.pointer {
  cursor: pointer;
}

.link-text {
  /* Link */
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px !important;
  line-height: 19px !important;

  /* Rewardle Blue */
  color: #569af5 !important;

  cursor: pointer;
}
