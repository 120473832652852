.paddingLeft-240p {
  padding-left: 237px;
}

.paddingTop-5r {
  padding-top: 2rem;
}

@media (max-width: 1200px) {
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}
