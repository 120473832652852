.rounded {
  border-radius: 0.75rem !important;
}

.btn-primary {
  background-color: var(--digitalblue) !important;
}

.btn-outline-primary {
  color: var(--digitalblue) !important;
  background-color: transparent !important;
  border: 2px solid var(--digitalblue) !important;
}

.h6 {
  font-size: 14px !important;
}

.md-form > label {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.md-form label {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem !important;
  color: #757575;
  cursor: text;
  -webkit-transition: color 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: color 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out, color 0.2s ease-out;
  transition: transform 0.2s ease-out, color 0.2s ease-out,
    -webkit-transform 0.2s ease-out;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

.md-form label.active {
  font-size: 1rem !important;
  -webkit-transform: translateY(-14px) scale(0.8);
  transform: translateY(-14px) scale(0.8);
}

input[type='number'] {
  -moz-appearance: textfield !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.card-body h5 {
  letter-spacing: 4px;
  color: var(--digitalblue) !important;
  margin-bottom: 1.8rem;
  white-space: pre-wrap;
}

.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  margin-left: -0.75rem;
}

.input-group-prepend {
  margin-right: -0.6rem;
}

.switch label input[type='checkbox']:checked + .lever:after {
  left: 1.5rem;
  background-color: var(--digitalblue);
}
