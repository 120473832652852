.top-nav-logo {
  width: 2rem;
  height: 2rem;

  & > img {
    border: 1px solid var(--blue250);
    background-color: var(--blue250);
  }
}

.dropdown-logo {
  width: 6rem;
  height: 6rem;
  align-items: center;
  text-align: center;
  & > img {
    border: 1px solid var(--blue250);
    background-color: var(--blue250);
    transform: translate3d(102px, 0px, 0px);
  }
}

.dropdown-logo-upload {
  width: 2rem;
  height: 2rem;
  margin-top: 0px;
  align-items: center;
  text-align: center;
  margin: 0px 2.5px 3px;
  // box-shadow: 0 1px 1px 0 rgb(65 69 73 / 30%), 0 1px 1x 1px rgb(65 69 73 / 15%);
  transform: translate3d(167px, -28px, 0px);
  & > i {
    border: 1px solid var(--blue250);
    background-color: var(--primarywhite);
    text-align: center;
    bottom: 0;
    -webkit-box-shadow: 0 1px 1px 0 rgb(65 69 73 / 30%),
      0 1px 3px 1px rgb(65 69 73 / 15%);
    box-shadow: 0 1px 1px 0 rgb(65 69 73 / 30%),
      0 1px 3px 1px rgb(65 69 73 / 15%);
    outline: 0;
    margin: 0px;
    position: absolute;
    right: 0;
    height: 26px;
    width: 26px;
    padding-top: 6.5px;
    padding-left: 0.5px;
  }
}

.dropdown-menu-right {
  min-width: 330px;
  text-align: center;
}

.navbar .dropdown-menu button:hover dropdown-item:hover {
  border: 0px;
}

.dropdown-item:hover {
  background-color: var(--primarywhite) !important;
  border: none;
  box-shadow: none;
  color: var(--digitalblue);
}

.modal.show .modal-dialog {
  float: right !important;
  width: 330px;
  padding-right: 0px;
  padding-top: 7px;
  text-align: center;
  // transform: translate3d(205px, 15px, 0px);
}

.btn-outline-default {
  color: var(--digitalblue) !important;
  background-color: transparent !important;
  border: 2px solid var(--digitalblue) !important;
}

.privacy-section {
  font-size: 12px;
  color: var(--grey400);
}

.btn-outline-default:hover {
  color: var(--digitalblue) !important;
  background-color: transparent !important;
  border: 2px solid var(--digitalblue) !important;
}

.btn-outline-default:not([disabled]):not(.disabled):active,
.btn-outline-default:not([disabled]):not(.disabled).active,
.show > .btn-outline-default.dropdown-toggle {
  background-color: transparent !important;
  color: var(--digitalblue) !important;
  border-color: var(--digitalblue) !important;
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
}
