.side-nav {
  background-color: var(--primarywhite) !important;
  color: var(--darkblueformenu);
  font-weight: 600 !important;
}

.side-nav .collapsible a {
  color: var(--darkblueformenu) !important;
  font-weight: 600 !important;
}

.side-nav .collapsible > li a.collapsible-header.active {
  color: var(--digitalblue) !important;
}
.side-nav .collapsible > li a.collapsible-header:hover {
  color: var(--digitalblue);
}
.side-nav .collapsible li a:hover {
  color: var(--digitalblue);
}

.side-nav li a.active {
  color: var(--digitalblue) !important;
}

.side-nav li a:hover {
  color: var(--digitalblue);
}

.side-nav .collapsible-body a {
  background-color: var(--primarywhite) !important;
}

.side-nav-submenu-show {
  display: block !important;
}

.side-nav-submenu-hide {
  display: none !important;
}

#emailCampaigns {
  display: block;
}

#account {
  display: block;
}

#cardDesign {
  display: block;
}

.navbar.scrolling-navbar {
  padding-top: 0px;
  padding-bottom: 0px;
}

.card.card-cascade .view.view-cascade.gradient-card-header {
  padding: 0.5rem;
}

.logo-wrapper {
  pointer-events: none;
}

.businessname {
  font-size: 25px !important;
  cursor: default;
}

@media (max-height: 992px) {
  .side-nav .logo-wrapper {
    height: 44px !important;
  }
}

.side-nav .logo-wrapper a {
  height: 44px !important;
}

.side-nav .logo-wrapper img {
  padding-left: 7px !important;
  padding-top: 3px !important;
}

.side-nav .collapsible a {
  height: 27px;
}

.side-nav .fa-angle-down.rotate-icon {
  display: none !important;
}
