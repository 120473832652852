// Brand colours
$blue100: #e6f0fe;
$blue200: #c5ddfc;
$blue400: #78aff7;
$blue600: #3687f3;
$blue700: #0d6ce7;
$blue250: #96c0f9;

$darkblue900: #021227;
$darkblueformenu: #192f67;
// Does not match with figma colors
// $blue60: #98c0f9;
// $darkblue120: #0a131f;
// $darkblue140: #101418;

$grey50: #f4f5f6;
$grey100: #e9eaed;
$grey200: #d2d6da;
$grey300: #bcc1c8;
$grey400: #9da5af;
$grey500: #848e9a;
$grey600: #656f7b;
$grey700: #49515a;
$grey800: #2e3238;
$grey900: #1c1e22;

$green50: #67c079;
$primarygreen: #3ca656;
$green200: #2a743c;

$orange50: #f9bb70;
$primaryorange: #f8ab4f;
$orange120: #e98309;

$red50: #fe6d7e;
$primaryred: #fd3b51;
$red200: #fd1933;

// Main colours
$primaryblue: #569af5;
$primaryblack: #000000;
$primarywhite: #ffffff;
