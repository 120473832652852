
.bg-primary {
  background-color: var(--grey50) !important;
}

.navbar {
  color: var(--digitalblue) !important;
  box-shadow: none !important;
}

.navbar .dropdown-menu a.active {
  background-color: var(--primarywhite);
  color: var(--digitalblue);
}

.navbar li a.active {
  background-color: var(--primarywhite);
  color: var(--digitalblue);
  border: 3px solid var(--digitalblue);
  border-radius: 5px;
}

.navbar .dropdown-menu a:not(.active) {
  color: var(--digitalblue);
}

.navbar .dropdown-menu a:hover {
  background-color: var(--digitalblue) !important;
  color: var(--primarywhite);
}

.navbar .dropdown-menu button {
  color: var(--digitalblue);
}

// .navbar .dropdown-menu button:hover {
//   background-color: var(--digitalblue) !important;
//   color: var(--primarywhite);
// }

.horizontal-navbar {
  -webkit-box-orient: horizontal;
  flex-direction: row;
}

.sideNav-toggle {
  line-height: '15px';
  margin-right: '1em';
  vertical-align: 'middle';
}

.navbar-brand{
  color: var(--digitalblue) !important;
  background-color: red;
}


@media (max-width: 1200px) {
  .navbar-nav{
    margin-right: 5px !important;
  }
}

.navbar-nav{
  font-weight: 800;
}

  .navbar-right-margin{
    margin-right: 220px !important;
  }
  .navbar-dark{    
    height: 2.7rem;
  }


  
  .nav-link {
    display: block;
    padding: 0rem 0rem !important; 
}
  
  .bordercolor {
    border-color: #9da5af !important;
  }

  @media (max-width: 1200px) {
 .navbar-nav-bar-icon{
  background: var(--primarywhite);
  height: 100%;
  padding: 15px;
 }

 .navbar-dark{
  padding: 0px;
 }
}