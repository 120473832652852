.root {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  height: 100% !important;
}
.progress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}
