
.max-Width {
  max-width: 768px;
}

.cardshadow {
  box-shadow: none !important;
  border-radius: 0px !important;
}

.registercontainer {
  max-width: 768px;
}

.sub-title{
  letter-spacing: 3px;
  font-size: 0.9rem;
  color: var(--darkblueformenu) !important;
  font-weight: 600 !important;
}

@media (max-width: 1200px) {
  .maincard{
    text-align: center !important;
  }
}